@import 'src/shared/styles/variables.scss';

.confirmation-wrapper {
  text-align: center;

  img {
    display: block;
    margin: 8rem auto 2rem;
    height: 3.5rem;
  }

  h1 {
    margin: 0 0 1.25rem;
  }

  p {
    font-weight: $font-weight-regular;
    margin: 0 auto;
    max-width: 19rem;
  }

  .button {
    margin-top: 3rem;
  }
}
