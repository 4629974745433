@import 'src/shared/styles/variables.scss';

.text-component {
  font-size: $font-base;
  line-height: $font-base * 1.5;
  font-weight: $font-weight-regular;
  color: $color-text-primary;

  &__inverted {
    color: $color-white;
  }

  &__gray {
    color: $color-text-footer;
  }

  &__large {
    font-size: $font-size-large;
    line-height: $font-size-large * 1.5;
  }

  &__small {
    font-size: 14px;
    line-height: 14px * 1.5;
  }

  &__x-small {
    font-size: $font-size-xx-small;
    line-height: $font-size-xx-small * 1.5;
  }

  &__bold {
    font-weight: $font-weight-demi-bold;
  }

  &__secondary {
    font-family: 'Pebble', sans-serif;
  }

  &__uppercase {
    text-transform: uppercase;
  }
}
