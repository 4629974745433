@import 'src/shared/styles/variables.scss';

#title-header {
  margin: 2rem 1rem 1rem;

  h2 {
    margin: 0;
    text-transform: uppercase;
    color: $color-purple;
    font-size: 1.75rem;
  }
}
