.new-payment-view {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .new-payment-view__payment-wrapper {
    margin: 0 auto;

    .section-title {
      margin-top: 0;
    }

    .adyen-credit-card-box {
      margin: 0;
    }
  }
}

.btn-text--green {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.btn-text--gray {
  color: #3e3e3e;
  font-weight: bold !important;
  letter-spacing: 1px;
}

.btn-form-wrapper {
  font-family: 'Helvetica Neue', sans-serif;
  display: inline-block;
  width: 17rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 2rem;
  line-height: 1;
  padding: 1rem 1.8rem;
  text-align: center;
  &.gray {
    background: #a0a0a0;
  }
  &.green {
    background: #00d066;
  }
}
