.processing-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .75);
  z-index: 1000;

  .loader {
    height: 4rem;
    width: 4rem;
  }
}