.saved-card-wrapper {
  margin: 1.5rem 1rem;
  
  .field-wrapper {
    &.list-layout {
      display: grid;
      grid-row-gap: 0.5rem;
    }
  
    &.block-layout {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 0.5rem;
    }
  }
}
