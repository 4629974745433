.registration-wrapper {
  display: flex;
  justify-content: space-evenly;
}

.registration-button {
  padding: 1em 2em;
  border-radius: 5px;
  background: white;
  text-decoration: none;
  font-weight: bold;
}

#failure {
  color: white;
  background: red;
  border: 1px solid red;
}

#success {
  color: white;
  background: #990ae3;
  border: 1px solid #990ae3;
}
