@import 'src/shared/styles/variables.scss';

.remove-subscription-view {
  .remove-section-wrapper {
    padding: 0 1rem;
    margin: 1rem auto 1.5rem;

    .remove-subscription-title {
      color: black;
      text-transform: none;
      padding-bottom: 1em;
      text-align: center;
    }

    .remove-subscription-container {
      padding: 1em;
      border-radius: 5px;
    }

    .remove-subscription-error-wrapper {
      p {
        margin: 1em 0;
      }
      .remove-subscription-description {
        text-align: center;
      }
    }
  }
  .red {
    color: white;
    background-color: $color-text-error;
  }

  .text-warning {
    color: $color-text-error;
  }

  .no-button {
    background-color: white;
    font-family: 'Helvetica Neue', sans-serif;
    padding: 1.5rem 1rem;
    border: none;
    text-align: left;
  }

  .grid-item {
    display: grid;
    grid-template-columns: 2rem auto;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1.5rem;
      }
    }

    .content-wrapper {
      grid-column: 2 / span 1;
    }
  }

  .remove-account-footer {
    position: fixed;
    width: 100%;
    bottom: 0;

    .checkbox-container {
      padding: 1rem 2rem;
      margin: 0;
      background: white;
    }
  }

  .remove-subscription-footer {
    max-width: 30rem;
    background-color: #f9f9f9;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 0.625rem;
    text-align: center;
    padding: 0;
    position: fixed;
    bottom: 0;

    .checkbox-container {
      padding: 1rem 2rem;
      margin: 0;
      background: white;
    }

    .row {
      .saved-card-info {
        display: flex;
        justify-content: space-between;
        height: auto;
        background-color: #f9f9f9;
        padding: 1rem 1rem 0.5rem;

        + .saved-card-info {
          padding-top: 0;
          padding: 0 1rem 1rem;
        }

        a {
          font-size: 0.9rem;
          text-decoration: none;
          color: #990ae3;
        }
      }
    }
  }

  .remaining-subscription-item-container {
    display: grid;
    grid-template-columns: 1em max-content;
    align-items: center;
    padding: 0 1em;
    grid-column-gap: 1em;
    &--large {
      @extend .remaining-subscription-item-container;
      grid-template-columns: 2em max-content;
    }

    .remaining-subscription-icon-wrapper {
      width: 1.5em;
    }

    .remaining-subscription-item-text {
      line-height: 1.2em;
      font-size: 0.8em;
      color: #2b2b2b;
    }
  }
}
