/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.adyenForm-container {
  text-align: center;

  .adyenForm-row {
    display: flex;
  }

  .adyenForm-select {
    width: 100%;
    border: 0.03125rem solid #ccc;
    border-radius: 0.125rem;
    padding: 1em 0.5em 1em 0.5em;
  }
}
