@import 'src/shared/styles/variables.scss';
.list-item-container {
  border-bottom: 1px solid $color-hr-gray-bright;
  min-height: 3.3em;
  padding: 1em 0;

  .list-item-wrapper {
    display: flex;
    align-items: center;

    .title {
      flex: 1;
      font-weight: $font-weight-regular;
    }

    .description {
      color: $color-text-secondary;
      font-weight: $font-weight-regular;
      padding: 0 0.5rem;
    }

    .icon-wrapper {
      display: flex;
      flex: 0;
      padding-right: 0.3rem;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}