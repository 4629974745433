.profile-view {
  .profile-section {
    margin: 0 auto;
    .section-title {
      margin: 0 0 1em;
    }
  }

  .profile-footer {
    background-color: white;
    font-family: 'Helvetica Neue', sans-serif;
    padding: 1.5rem 1rem;
    position: fixed;
    bottom: 0;

    .footer-section {
      margin: 1rem auto;
      max-width: 16rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
