@import 'src/shared/styles/variables.scss';

.back-button-header-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0.75rem 1rem 3rem;

  a {
    display: flex;

    svg {
      height: 1.5rem;
      fill: #222;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
}
