@import-normalize; // Bundled with CRA -- @TODO: Fix VSC SCSS lint warning

@import 'src/shared/styles/variables.scss';

@font-face {
  font-family: 'Pebble';
  src: url('../fonts/Pebble_W_Rg.woff') format('woff');
}

* {
  box-sizing: border-box;
}

html {
  font-size: $font-base;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%heading-shared {
  font-family: 'Pebble', sans-serif;
  color: $color-purple;
  text-transform: uppercase;
  font-weight: $font-weight-regular;
}

h1 {
  @extend %heading-shared;
  font-size: $font-size-x-large;
}

h2 {
  @extend %heading-shared;
  font-size: $font-size-large;
}

h3 {
  @extend %heading-shared;
  font-size: $font-size-medium;
}

h4 {
  @extend %heading-shared;
  font-size: $font-size-medium;
}

p {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  color: $color-text-primary;
  font-size: $font-size-medium;
  font-weight: $font-weight-medium;
  margin: 0;
}

label,
input,
textarea,
select {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  /* Prevents zooming for input fields */
  font-size: initial;
}

.button {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  display: inline-block;
  width: 17rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 2rem;
  line-height: 1;
  padding: 1rem 1.8rem;
  text-align: center;

  &.green {
    background-color: $color-button-enabled;
    color: $color-text-primary;
  }

  &.purple {
    background-color: $color-purple;
    color: $color-text-white;
  }

  &.gray {
    background-color: $color-button-disabled;
    color: $color-text-disabled;
  }

  &.small {
    padding: 0.5rem 1.5rem;
    width: auto;
    font-size: 14px;
  }
}

.button.secondary {
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff;

  &.purple {
    border-color: $color-purple;
    color: $color-purple;
  }
}

.white-border {
  border: 0.1em;
  border-color: $color-white;
  border-style: solid;
}

.no-border {
  border: 0;
}
