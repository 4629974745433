@import 'src/shared/styles/variables.scss';

.activate-subscription-view {
  height: 100%;

  .activate-section-wrapper {
    padding: 0 1rem;

    .activate-subscription-title {
      color: black;
      text-transform: none;
      padding-bottom: 1em;
      text-align: center;
    }

    .activate-subscription-container {
      padding: 1em;
      border-radius: 5px;
    }

    .activate-subscription-error-wrapper {
      p {
        margin: 1em 0;
      }
      .activate-subscription-description {
        text-align: center;
      }
    }
  }

  .section-wrapper {
    padding: 0 1rem;
    margin: 1rem auto 1.5rem;
  }

  .red {
    color: white;
    background-color: $color-text-error;
  }

  .text-warning {
    color: $color-text-error;
  }

  .no-button {
    background-color: white;
    font-family: 'Helvetica Neue', sans-serif;
    padding: 1.5rem 1rem;
    border: none;
    text-align: left;
  }

  .grid-item {
    display: grid;
    grid-template-columns: 2rem auto;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1.5rem;
      }
    }

    .content-wrapper {
      grid-column: 2 / span 1;
    }
  }

  .next-billing-info {
    display: flex;
    justify-content: space-between;
    height: auto;
    background-color: #f9f9f9;
    padding: 1rem 1rem 0.5rem;
  }

  .remaining-subscription-item-container {
    display: grid;
    grid-template-columns: 1em max-content;
    align-items: center;
    padding: 0 1em;
    grid-column-gap: 1em;
    &--large {
      @extend .remaining-subscription-item-container;
      grid-template-columns: 2em max-content;
    }

    .remaining-subscription-icon-wrapper {
      width: 1.5em;
    }

    .remaining-subscription-item-text {
      line-height: 1.2em;
      font-size: 0.8em;
      color: #2b2b2b;
    }
  }
}
