@import 'src/shared/styles/variables.scss';

.web-view-container {
  > .title-wrapper {
    margin-top: 1.5rem;

    h1,
    h2,
    p {
      text-align: center;
    }

    p {
      margin: 0 1.75rem;
      font-size: $font-size-small;
    }
  }
  > .row {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: $width-max-page;

    &.profile-wrapper {
      padding: 0 1rem;
    }

    &.button-wrapper {
      display: flex;
      justify-content: center;

      .list-layout {
        display: grid;
        grid-row-gap: 1rem;
      }
    }
  }

  .subscription-cancel-basic {
    font-size: 0.8rem;
    color: #838383;
    margin: 1em 0;
    font-weight: 400;
  }

  .section-wrapper {
    padding: 0 1rem;
    margin: 3rem auto 1.5rem;

    &__sub {
      margin-top: 1.5rem;
    }

    .list-layout {
      display: grid;
      grid-row-gap: 0.5rem;
    }

    > .button-wrapper {
      display: flex;
    }

    > .section-title {
      color: $color-text-primary;
      margin: 3rem 0 0.75rem;

      &__sub {
        margin-top: 1.5rem;
      }
    }
  }
}
