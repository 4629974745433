@import 'src/shared/styles/variables.scss';

.price-info {
  display: flex;
  justify-content: space-between;
  height: auto;
  background-color: $color-gray-light;
  padding: 0 1rem;
  border-top: 0.0125rem solid $color-border-gray-light;
  border-bottom: 0.0125rem solid $color-border-gray-light;

  h3 {
    color: $color-text-primary;
    font-weight: $font-weight-demi-bold;
    text-transform: none;
  }

  .title h3 {
    font-family: 'Helvetica Neue', sans-serif;
  }
  .icon,
  .amount {
    float: right;
    img {
      height: 2.5rem;
      margin-right: 2.25em;
    }
  }

  .icon {
    display: flex;
    align-items: center;
  }
}
