$font-size-medium: 0.875rem;
$color-text-secondary: #838383;
$color-purple: #990ae3;
$color-text-primary: #222222;
$color-input-border: #ccc;
$color-text-error: #e4175c;
$color-background-error: #fff5f9;
$color-button-primary: #00cc66;
$color-button-disabled: #ccc;

.adyenFormItem-field-wrapper--error {
  margin-bottom: 1.5rem !important;
  .adyenFormItem-error-message {
    font-family: 'Helvetica Neue', sans-serif;
    color: #e4175c;
    padding: 5px 12px;
    margin-top: 0;
    align-items: center;
    display: flex;
    font-size: 0.75em;
    font-weight: 400;
  }
}

.adyenFormItem-field-wrapper {
  width: 100%;
  height: 3.685rem;
  padding: 0;
  margin-bottom: 0.5rem;

  + .adyenFormItem-field-wrapper {
    margin-left: 16px;
  }

  .adyenFormItem-label:focus-within {
    .adyenFormItem-label__input {
      border-color: $color-purple;
    }
    .adyenFormItem-label__text {
      font-size: 12px !important;
      top: 8px !important;
      left: 12px !important;
      color: $color-purple;
    }
  }

  .adyenFormItem-label--error {
    .adyenFormItem-label__input {
      border-color: #e4175c !important;
      background: #fff5f9;
    }
    .adyenFormItem-label__text {
      font-size: 12px !important;
      top: 8px !important;
      left: 12px !important;
      color: #e4175c !important;
    }
  }

  .adyenFormItem-label--focused,
  .adyenFormItem-label:focus {
    .adyenFormItem-label__input {
      border-color: $color-purple;
    }
    .adyenFormItem-label__text {
      font-size: 12px !important;
      top: 8px !important;
      left: 12px !important;
      color: $color-purple;
    }
  }
  .adyenFormItem-label--filled {
    .adyenFormItem-label__text {
      font-size: 12px !important;
      top: 8px !important;
      left: 12px !important;
    }
  }
  .adyenFormItem-label {
    box-sizing: border-box !important;
    position: relative;
    width: 100%;
    display: block;
    height: 100%;

    .adyenFormItem-label__text {
      position: absolute;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
      z-index: 1;
      top: 20px;
      left: 13px;
      color: #838383;
      font-size: 0.875rem;
      box-sizing: border-box;
      pointer-events: none;
    }

    .adyenFormItem-label__input {
      outline: none;
      box-sizing: border-box;
      border: 0.03125rem solid #ccc;
      border-radius: 0.125rem;
      position: relative;
      height: 100%;
      width: 100%;
      padding: 8px 12px 0px;
      &:read-only {
        border: 0.03125rem solid transparent;
        pointer-events: none;
      }
    }
    .adyenFormItem-label__input:placeholder-shown {
      color: rgb(185, 196, 201);
      font-weight: 200;
    }
  }
}
