.processing-wrapper--small {
  display: flex;
  padding: 2rem;
  justify-content: center;

  .loader {
    height: 2rem;
    width: 2rem;
  }
}
