@import 'src/shared/styles/variables.scss';

.link-item-remove-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-hr-gray-bright;
  height: 3.375rem;
  text-decoration: none;

  &.profile {
    height: 5rem;
  }

  .title-wrapper {
    flex: 1;

    h2 {
      color: $color-text-error;
      margin: 0;
    }

    .title {
      font-weight: $font-weight-demi-bold;
    }

    .sub-title {
      font-size: $font-size-x-small;
      color: $color-text-error;
      margin-top: 0.3rem;
      font-weight: $font-weight-regular;
    }
  }

  .description {
    color: $color-text-error;
    font-weight: $font-weight-regular;
    padding: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }

  .icon-wrapper {
    display: flex;
    flex: 0;

    img {
      width: 1.5rem;
      opacity: 0.8;
    }
  }
}
