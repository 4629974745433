@import 'src/shared/styles/variables.scss';

%select-label-notched {
  top: 0;
  left: 11px;
  position: relative;
  margin-top: 8px;
  font-size: 12px;
}

.select-wrapper {
  .select-container {
    position: relative;

    .select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      position: relative;
      width: 100%;
      align-self: auto;
      box-sizing: border-box;
      height: auto;
      border: none;
      border-radius: 2px;
      color: $color-text-primary;
      font-size: $font-size-medium;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
      padding: 1.5rem 2.5rem 0.75rem 0.75rem;
      background: url(./dropdown.svg);
      background-repeat: no-repeat;
      background-position: center right 1rem;

      &:focus {
        outline: none;
      }

      &:focus ~ .select-outline {
        border-color: $color-purple;

        .select-label {
          @extend %select-label-notched;
          color: $color-purple;
        }
      }

      &.error {
        background: $color-background-error;
      }

      &.error ~ .select-outline {
        border-color: $color-text-error;

        .select-label {
          color: $color-text-error;
        }
      }
    }

    .select-outline {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      height: 100%;
      width: 100%;
      max-width: 100%;
      pointer-events: none;
      box-sizing: border-box;
      border: 1px solid $color-border-gray-light2;
      border-radius: 2px;

      &.notched .select-label {
        @extend %select-label-notched;
      }

      .select-label {
        position: absolute;
        top: 18px;
        left: 12px;
        display: inline-block;
        max-width: 100%;
        color: #6e6e6e;
        pointer-events: none;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  .select-helper-text {
    &.error {
      display: block;
      color: $color-text-error;
      padding: 5px 12px;
      font-size: 12px;
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
