@import 'src/shared/styles/variables.scss';

.billing-info-container {
  padding: 0 0 1rem 0;
  border-bottom: 1px solid $color-hr-gray-bright;
  .billing-info-item-wrapper:first-child {
    padding-top: 0;
  }
  .billing-info-item-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
  }
  .billing-info-item {
    font-size: 0.9rem;
    font-weight: $font-weight-regular;
    color: $color-text-secondary;

    + .billing-info-item {
      padding: 0 0.4rem;
    }
  }
}
