@import 'src/shared/styles/variables.scss';

.form-header-wrapper {
  font-family: 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0.75rem 1rem 3rem;
  height: 1.5rem;

  .title {
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
}

.btn {
  background: none;
  font-size: 1em;
  border: none;

  &--clickable {
    cursor: pointer;
  }

  &--disabled {
    color: $color-text-disabled;
    pointer-events: none;
  }
}
