@import 'src/shared/styles/variables.scss';

.button-navigation-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 1rem 1rem 3rem;

  .button-navigation-header-icon {
    width: 1em;
  }

  a {
    display: flex;
    text-decoration: none;
    align-items: center;

    p {
      color: $color-purple;
    }

    svg {
      height: 1.5rem;
      fill: #222;
    }
  }
  .button-navigation-text-icon {
    padding-right: 0.5em;
  }

  .button-navigation-text--active {
    color: #990ae3;
  }

  .button-navigation-text--inactive {
    color: #a4a3a3 !important;
  }

  .title {
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
}
