@import 'src/shared/styles/variables.scss';

.footer {
  max-width: $width-max-page;
  background-color: $color-gray-bright;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: $font-size-xxx-small;
  padding: 1.5rem 1.5rem 2rem;
  border-top: 0.03125rem solid $color-border-gray-light;
  text-align: center;

  .footer-section {
    margin: 1rem auto;
    max-width: 16rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.subscription-footer {
  max-width: $width-max-page;
  background-color: $color-gray-bright;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: $font-size-xxx-small;
  text-align: center;
  padding: 0 0 1.25rem 0;
  position: fixed;
  bottom: 0;

  .checkbox-container {
    padding: 1rem 2rem;
    margin: 0;
    background: white;
  }

  .row {
    .saved-card-info {
      display: flex;
      justify-content: space-between;
      height: auto;
      background-color: #f9f9f9;
      padding: 1rem 1rem 0.5rem;

      + .saved-card-info {
        padding-top: 0;
        padding: 0 1rem 1rem;
      }

      a {
        font-size: 0.9rem;
        text-decoration: none;
        color: #990ae3;
      }
    }
  }
}
